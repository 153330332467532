import React from "react";
import './Testimonial.css'
import { Swiper , SwiperSlide } from "swiper/react";
import 'swiper/css';
import profilePic1 from '../../img/profile1.jpeg';
import profilePic2 from '../../img/profile1.jpeg';
import profilePic3 from '../../img/profile1.jpeg';
// import profilePic4 from '../../img/profile4.jpg';
import { Pagination } from "swiper";
import "swiper/css/pagination";
import { themeContext } from '../../Context';
import { useContext } from 'react'

const Testimonial = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
    const clients = [
      {
        img: profilePic1,
        review:
          "E-commerce Website Using Django.",
      },
      {
        img: profilePic2,
        review:
          "Car Rental Management System using Django",
      },
      {
        img: profilePic3,
        review:
          "My Portfolio using React Js",
      },
      
    ];
    return (
        <div className="t-wrapper">
             <div className="t-heading">
                <span style={{color: darkMode? 'white': ''}}>Client always get</span>
                <span>Exception Work</span>
                <span style={{color: darkMode? 'white': ''}}>From me...</span>
            <div className="blur t-blur1" style={{background: "var(--purple)"}}></div>
            <div className="blur t-blur2" style={{background: "skyblue"}}></div>
            </div>
            {/* slider*/}
            <Swiper 
           modules={[Pagination]}
           slidesPerView={1}
           pagination={{ clickable: true }}
            >
            {clients.map((client, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="testimonial" id="Testimonials">
                <img src={client.img} alt="" />
                <span>{client.review}</span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};


export default Testimonial;