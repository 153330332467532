
import * as React from 'react'
import './intro.css';
import Github from '../../img/github.png';
// import Facebook from "../../img/Facebook.png";
import Instagram from '../../img/instagram.png';
import Linkedin from '../../img/linkedin.png';
// import Vector1 from '../../img/Vector1.png';
// import Vector2 from '../../img/Vector2.png';
// import boy from '../../img/boy.png';
import thumbup from '../../img/thumbup.png';
import crown from '../../img/crown.png';
import glassesimoji from '../../img/glassesimoji.png';
import FloatingDiv from '../FloatingDiv/FloatingDiv';
import { themeContext } from '../../Context';
import { useContext } from 'react';
import { motion } from 'framer-motion';
import {Link} from 'react-scroll';






const Intro = () => {
    const transition = { duration: 2, type: "spring" };

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    return ( 
        <div className='Intro' id='Intro'>
            <div className="i-left">
                <div className="i-name">
                    <span style={{color: darkMode? 'white': ''}}>Hello! I Am</span>
                    <span>Aashish Mehta</span>
                    <span style={{color: darkMode? 'white': ''}}> Passionate Cybersecurity Student with Strong Analytical and Problem-Solving Skills,<br />
                         Dedicated to Ensuring Data Protection and Information Security.</span> 
                    </div>
                    <Link to="contact" smooth={true} spy={true}>
                      <button className="button s-button">Hire Me</button>
                    </Link>
                    <div className='i-icons'>
                        <a href='https://github.com/aasis099'>
                        <img src={Github} alt='' />
                        </a>
                        {/* <a href=' https://www.facebook.com/profile.php?id=100094052887616&mibextid=ZbWKwL'>
                        <img src={Facebook} alt="" /></a> */}
                        <a href='https://www.instagram.com/aasis_100/'>
                        <img src={Instagram} alt="" /></a>
                        <a href='https://www.linkedin.com/in/aasis2002/'>
                        <img src={Linkedin} alt=''/></a>
                    </div>
                    </div>
            <div className="i-right">
                
                <motion.img
                 initial={{ left: "-36%" }}
                 whileInView={{ left: "-24%" }}
                 transition={transition}  
                src={glassesimoji}
                alt= ""
                /> 
                <motion.div
          initial={{ top: "-4%", left: "74%" }}
          whileInView={{ left: "68%" }}
          transition={transition}
                
                  style={{top:'-4%', left:'65%'}}>
                  <FloatingDiv image={crown} txt1='Web' txt2='Developer' />
                </motion.div>
                <motion.div
          initial={{ left: "1rem", top: "18rem" }}
          whileInView={{ left: "0rem" }}
          transition={transition}
                style={{top:'18rem', left: '1rem'}}>
                    <FloatingDiv image={thumbup} txt1='Best Design' txt2='Award' />
                </motion.div>
                <div className='blur' style={{background: "rgb(238 210 255)"}}>

                </div>
                <div className="blur"
                style={{
                    background: '#c1f5ff',
                    top:'17rem',
                    width: '21rem',
                    height: '11rem',
                    left: '-9rem'
                }}>
                </div>
            </div>


        </div>

    );
};

export default Intro;